.main-background {
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
}

.select-wrapper {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
}

.select-fill {
  fill: rgba(0,0,0,0);
}

.select-person {
  cursor: pointer;
}

.select-person:hover {

}

.main-header {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 2;
  text-align: center;

  color: white;
  font-family: 'Train One', sans-serif;
  font-size: 36pt;
  backdrop-filter: blur(5px);

  background-color: rgba(240, 209, 175, 0.7);
  padding: 10px;
}

.soundcloud-div {
  font-size: 12pt; 
  color: white;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
  font-weight: 100;
}

.soundcloud-a {
  color: white; 
  text-decoration: none;
}